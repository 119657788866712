
.cld-video-player .vjs-title-bar {
  display: flex;
  flex-direction: column;
  align-items: center;

  visibility: visible;
  opacity: 1;

  transition: opacity 0.1s;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2em;
  padding: clamp(1.3em, 4cqw, 2em);
  pointer-events: none;
  container-type: inline-size;

  .vjs-title-bar-title {
    width: 100%;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5em;
    margin-bottom: clamp(0.2em, 1.5cqw, 0.5em);;
    font-size: 1.8em;
    font-size: clamp(1.3em, 4cqw, 1.8em);
  }

  .vjs-title-bar-subtitle {
    width: 100%;
    font-weight: 100;
    line-height: 1;
    font-size: 1em;
    font-size: clamp(0.8em, 3cqw, 1.1em);
  }
}

// Video has started playing AND user is inactive
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-title-bar {
  // Remain visible for screen reader and keyboard users
  visibility: visible;
  opacity: 0;

  transition: opacity 1.0s;
}

.vjs-controls-disabled .vjs-title-bar,
.vjs-using-native-controls .vjs-title-bar,
.vjs-error .vjs-title-bar,
.vjs-ad-playing .vjs-title-bar {
  // !important is ok in this context.
  display: none !important;
}

// Don't hide the control bar if it's audio
.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-title-bar {
  opacity: 1;
  visibility: visible;
}
